<template>
  <a-card :bordered="false" class="card-area">
    <div class="page-form-advanced-search">
      <a-form class="ant-advanced-search-form">
        <a-row>
          <a-col :md="8" :sm="24">
            <a-form-item
              label="课程名称"
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 18, offset: 1 }"
            >
              <a-input v-model="queryParams.title" />
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24">
            <a-form-item
              label="状态"
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 18, offset: 1 }"
            >
              <a-select
                show-search
                placeholder="选择状态"
                v-model="queryParams.status"
                style="width: 100%"
              >
                <a-select-option
                  v-for="s in status"
                  :key="s.keyy"
                  :value="s.keyy"
                  >{{ s.valuee }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12"> </a-col>
          <a-col :span="12" :style="{ textAlign: 'right' }">
            <a-button type="primary" @click="search">查询</a-button>
            <a-button style="margin-left: 8px" @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="operator">
      <a-button type="primary" @click="clickAdd()">新增</a-button>
      <a-button @click="batchDelete" >删除</a-button>
    </div>
    <div>
      <!-- 表格区域 -->
      <a-table
        ref="TableInfo"
        :columns="columns"
        :rowKey="(record) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :row-selection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}" 
        bordered 
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 900 }"
      >
      <template slot="userlist" slot-scope="list, record">
        <template v-for="(data, index) of list">
          <a-tag
            style="margin: 0 5px 5px"
            :key="index"
            v-if="record.showAll || index < 6"
          >
            {{ data.username }}
          </a-tag>
        </template>

        <span
          style="white-space: nowrap; color: #3db6fc; cursor: pointer;"
          v-if="list.length > 6"
          @click="record.showAll = !record.showAll"
        >
          {{ record.showAll ? "折叠▲" : "展开▼" }}
        </span>
      </template>
        <template slot="deptId" slot-scope="deptId">
          {{
            deptList.find((item) => item.id == deptId)
              ? deptList.find((item) => item.id == deptId).title
              : ""
          }}
        </template>
        <template slot="operation" slot-scope="text, record">
          <a-dropdown>
            <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
              <a-icon
                type="setting"
                theme="twoTone"
                twoToneColor="#4a9ff5"
              ></a-icon>
            </a>
            <a-menu slot="overlay">
              <a-menu-item key="0">
                <a target="_blank" @click="clickEdit(record)">编辑</a>
              </a-menu-item>
              <a-menu-divider />
              <a-menu-item
                key="3"
                @click="
                  $router.push({
                    path: '/training/user',
                    query: {
                      trainingId: record.id,
                    },
                  })
                "
              >
                成绩录入
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </template>
        <template slot="trainingMethod" slot-scope="trainingMethod">
          <a-tag>{{ method[trainingMethod] }}</a-tag>
        </template>
        <template slot="appraiseType" slot-scope="appraiseType">
          <a-tag>{{ appraise[appraiseType] }}</a-tag>
        </template>
      </a-table>
      <training-offline-detail
        ref="trainingOfflineDetail"
        @success="handleSuccess"
      >
      </training-offline-detail>
    </div>
  </a-card>
</template>
<script>
import TrainingOfflineDetail from "@/views/training/TrainingOfflineDetail";
import { platFn } from "@/utils/common.js";
import { mapState } from "vuex";
import {getDeptRootId} from '@/utils/tools'

export default {
  name: "trainingOffline",
  components: { TrainingOfflineDetail },
  data() {
    return {
      selectedRowKeys: [],
      dataSource: [],
      deptTreeData: [],
      handleType: 0,
      type: {},
      method: {},
      appraise: {},
      pagination: {
        pageSizeOptions: ["1", "10", "20", "30", "40", "100"],
        defaultCurrent: 1,
        defaultPageSize: 10,
        total: 0,
        current: 1,
        pageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) =>
          `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`,
        onChange: (current, pageSize) => {
          this.pagination.current = current;
          this.pagination.pageSize = pageSize;
        },
        onShowSizeChange: (current, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
        },
      },
      queryParams: {
        trainingMethod: 0,
      },
      drawerShow: false,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      status: (state) => state.dict.dicts.training_status,
      appraiseType: (state) => state.dict.dicts.training_appraise_type,
      trainingMethod: (state) => state.dict.dicts.training_training_method,
    }),
    deptList() {
      return platFn(this.deptTreeData);
    },
    columns() {
      return [
        {
          title: "培训对象",
          dataIndex: "userlist",
          minWidth: 300,
          scopedSlots: { customRender: "userlist" },
        },
        {
          title: "发起部门",
          width: 150,
          dataIndex: "deptId",
          scopedSlots: { customRender: "deptId" },
        },
        {
          title: "标题",
          width: 200,
          dataIndex: "title",
        },
        {
          title: "培训形式",
          width: 100,
          dataIndex: "trainingMethod",
          scopedSlots: { customRender: "trainingMethod" },
        },
        {
          title: "讲师",
          width: 100,
          dataIndex: "teacher",
        },
        {
          title: "评估方式",
          width: 100,
          dataIndex: "appraiseType",
          scopedSlots: { customRender: "appraiseType" },
        },
        {
          title: "状态",
          width: 100,
          dataIndex: "status",
          customRender: (text, row, index) => {
            for (let _index in this.status) {
              let key = this.status[_index].keyy
              if (text === key) {
                let val = this.status[_index].valuee
                let color = this.status[_index].otherKeyy
                return <a-tag color={color}>{val}</a-tag>
              } else {
                continue
              }
            }
            return text
          },
        },
        {
          title: "操作",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
          width: 100,
        },
      ];
    },
  },
  mounted() {
    this.getJson();
    this.fetch({
      ...this.queryParams,
    });
    this.$get("dept", { rootDeptIds: getDeptRootId() }).then((r) => {
      this.deptTreeData = r.data.rows.children;
    });
  },
  methods: {
    getJson() {
      let _this = this;
      _this.trainingMethod.forEach((dictType) => {
        let { keyy, valuee } = dictType;
        keyy = Number(keyy);
        _this.method[keyy] = valuee;
      });
      _this.appraiseType.forEach((dictType) => {
        let { keyy, valuee } = dictType;
        keyy = Number(keyy);
        _this.appraise[keyy] = valuee;
      });
    },
    search() {
      this.fetch({
        ...this.queryParams,
      });
    },
    reset() {

      // 重置查询参数
      this.queryParams = { trainingMethod: 0 };
      this.pagination.current = 1;
      this.fetch({
        ...this.queryParams,
      });
    },
    handleTableChange(pagination, filters, sorter) {
      this.fetch({
        ...this.queryParams,
      });
    },
    fetch(params = {}) {
      this.loading = true;
      params.pageSize = this.pagination.pageSize;
      params.pageNum = this.pagination.current;

      this.$get("training/detail/page", {
        ...params,
      }).then((r) => {
        let data = r.data.data;
        const pagination = { ...this.pagination };
        pagination.total = data.total;
        this.pagination = pagination;
        this.loading = false;
        if (data.rows == undefined) {
          this.dataSource = []
          return
        }
        
        let datasource = data.rows;
        let set = new Set();
        for (let item of datasource) {
          for (const id of item.userIdValueList) {
            set.add(id);
          }
        }
        let ids = [...set].join(",");

        this.$get(`user/list/simple/${ids}`).then((resp) => {
          let arr = resp.data.data;

          for (let item of datasource) {
            item.userlist = []
            item.showAll = false;
            for (const id of item.userIdValueList) {
              let one = arr.find((i) => i.userId == id);
              if (one) {
                item.userlist.push(one);
              }
            }
            item.creator = arr.find((i) => i.userId == item.createUserId);
          }

          this.dataSource = datasource;
        });
      });
    },
    clickAdd() {
      this.$refs.trainingOfflineDetail.setForm(0);
    },
    clickEdit(record) {
      this.$refs.trainingOfflineDetail.setForm(1, record);
    },
    handleSuccess() {
      this.reset();
    },
    onSelectChange (selectedRowKeys) {
      // console.log("selectedRowKeys==>", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    batchDelete() {
      if(!this.selectedRowKeys.length) {
        this.$message.warning("请选择需要删除的记录");
      } else {
        let that = this;
        that.$confirm({
          title: "确定删除所选中的记录？",
          content: "当您点击确定按钮后，这些记录将会被彻底删除",
          centered: true,
          onOk () {
            let ids = that.selectedRowKeys.join();
            that.$delete(`training/${ids}`)
              .then(() => {
                that.$message.success("删除成功！");
                that.selectedRowKeys = [];
                that.search();
              })
              .catch(e => console.log("企业新闻删除失败！==> ", e));
          },
          onCancel () {
            that.selectedRowKeys = [];
          }
        })
      }
    }
  },
};
</script>
<style lang="less" scoped>
@import "../../utils/Common.less";
</style>
